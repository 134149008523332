/**
* CONTENTS
*
* BANNER-IMAGE
*
* ARTICLE-DETAIL
*
* FIGURES-AND-CAPTIONS
*
* OVERFLOW SCROLLING
*
* GO-TO-HOMEPAGE
*/





/*------------------------------------*\
 #BANNER-IMAGE
\*------------------------------------*/


.banner-container {
  position: relative;
  margin: 0 -2rem;
  padding: 2rem 2rem 0;
  background-color: $color-slate;
  background-position: center center;
  background-size: cover;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    max-width: 71rem;
    margin: 0 auto;
    border: .1rem solid rgba( 0, 0, 0, .4 );
    border-bottom-width: 0;
    border-radius: 0;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
    box-shadow: 0 0 2em 2em rgba( 0, 0, 0, .1 );
  }

  @media screen and (min-width: $breakpoint-md) {
    padding: calc( 1em + 3vw + 2vh ) calc( 1em + 3vw + 2vh ) 0;

    img {
      max-width: 120em;
      border-top-left-radius: .4rem;
      border-top-right-radius: .4rem;
    }

  }
  
  &.bettter {
    background-image: url( '../bettter/bettter-blur.jpg' );
  }

  &.syracuse-calendar {
    background-image: url( '../syracuse-calendar/syracuse-calendar-blur.jpg' );
  }

  &.syracuse-cobalt {
    background-image: url( '../syracuse-cobalt/syracuse-cobalt-blur.jpg' );
  }

  &.syracuse-edu {
    background-image: url( '../syracuse-edu/syracuse-edu-blur.jpg' );
  }

  // Add semi-transparent border to banner bottom
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .1rem;
    background: rgba( 0, 0, 0, .3 );
  }

}





/*------------------------------------*\
 #ARTICLE-DETAIL
\*------------------------------------*/


article {
  padding: 0 2rem 6em;
  text-shadow: .1rem .1rem 0 rgba( 255, 255, 255, .2 );

  .text-container {
    max-width: 64em;
    margin-top: 2.5em;
  }

  .eyebrow {
    margin-bottom: .5em;
    font-size: $font-size-detail-eyebrow-sm;
    letter-spacing: .15rem;
  }

  h1 {
    margin-bottom: .7em;
    font-size: $font-size-detail-h1-sm;
    line-height: 1.4;
  }

  h2 {
    margin: .4em 0 .7em;
    font-size: $font-size-detail-h2-sm;
  }

  ol {
    list-style: decimal;
    margin-bottom: 1em;
    padding-left: 1em;
    font-size: $font-size-p-sm;
  }

  li {
    margin-bottom: .7em;
    padding-left: .35em;
  }

  @media screen and (min-width: $breakpoint-xs) {

    .text-container {
      margin-top: 4em;
    }

    .eyebrow {
      font-size: $font-size-detail-eyebrow-md;
    }

    h1 {
      font-size: $font-size-detail-h1-md;
    }

    h2 {
      font-size: $font-size-detail-h2-md;
    }

    header p {
      font-size: $font-size-detail-header-p-md;
    }

    ol {
      font-size: $font-size-p-md;
      column-count: 2;
    }

    li {
      break-inside: avoid;
    }

  }

}





/*------------------------------------*\
 #FIGURES-AND-CAPTIONS
\*------------------------------------*/


figure {
  margin: 3em 0 5em;
  padding-top: 2rem;
  -webkit-overflow-scrolling: touch;

  .image-container {
    position: relative;
    display: table; // Needed to collapse outline to image width
    margin: 0 auto 2em;

    // Fix for Internet Explorer 10 and 11
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: block;
    }

    // Add semi-transparent border once lazy-loaded
    &.outline.loaded:after {
      box-shadow: inset 0 0 0 .1rem rgba( 0, 0, 0, .3 );
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: .4rem;
      box-shadow: inset 0 0 0 .1rem rgba( 0, 0, 0, .3 );
    }

  }

  img {
    display: block;
    border-radius: .4rem;
  }

}

figcaption {
  position: relative;
  max-width: 62rem;
  margin: 1em auto 2em;
  padding-left: 3rem;
  font-size: $font-size-p-sm;
  white-space: normal;
  background: url('../img/arrow.svg') no-repeat left .35em;
  background-size: .8em;
}

@media screen and (min-width: $breakpoint-xs) {

  figcaption {
    font-size: $font-size-p-md;
  }

}





/*------------------------------------*\
  #OVERFLOW-SCROLLING
\*------------------------------------*/


.scroll-container {
  display: block;
  margin: 0 -2rem;
  padding-left: 2rem;
  text-align: center;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none !important;
  }

  .image-container {
    position:relative;
    display: inline-block;
    width: 85%;
    max-width: 80em;
    margin-right: 2rem;
    vertical-align: top;

    &:first-child:nth-last-child(2),
    &:first-child:nth-last-child(2) ~ .image-container {

      @media screen and (min-width: $breakpoint-sm) {
        width: 60%;
      }

      @media screen and (min-width: $breakpoint-lg) {
        width: calc( 50% - 2.5rem );
      }
      
    }

  }

  &.mobile .image-container {
    min-width: 18em;
    width: 57%;
    max-width: 40em;

    @media screen and (min-width: $breakpoint-sm) {
      width: 37%;
    }

    @media screen and (min-width: $breakpoint-md) {
      width: calc( 33.333% - 2.5rem );
    }

    &:first-child:nth-last-child(4),
    &:first-child:nth-last-child(4) ~ .image-container {
      max-width: 37em;

      @media screen and (min-width: $breakpoint-lg) {
        width: calc( 25% - 2.5rem );
      }

    }

  }

  &.deck .image-container {

    // Prevent unloaded images from rendering as a square due to the 1px by 1px placeholder image
    &:not(.loaded) {
      height: 0;
    }

    @media screen and (min-width: $breakpoint-md) {
      width: 44%;
      max-width: 70em;
    }

  }

}





/*------------------------------------*\
  #GO-TO-HOMEPAGE
\*------------------------------------*/


#go-to-homepage {
  position: fixed;
  bottom: 1.8rem;
  left: 1.8rem;
  align-items: center;
  width: 2.6em;
  height: 2.6em;
  font-size: 2.5rem;
  line-height: 1;
  text-align: center;
  background: $color-off-white;
  border: 1em solid $color-off-white;
  border-width: .6em .8em;
  border-radius: 9rem;
  box-shadow: 0 0 1em rgba( 0, 0, 0, .15 );
  white-space: nowrap;
  z-index: 2;

  &:focus, 
  &.page-bottom {
    width: auto;
    color: $color-slate;
    box-shadow: 0 0 1em rgba( 0, 0, 0, .15 );
    overflow: hidden;
  }

  &:active {
    background: $color-off-white;
  }

  img {
    position: relative;
    display: inline;
    width: 2.5rem;
    height: 2.5rem;
    vertical-align: middle;
    z-index: 2;
  }

  strong {
    display: inline-block;
    margin-left: .8em;
    color: $color-off-white;
    font-size: 75%;
    font-weight: 700;
    text-indent: -99rem;
    border-bottom: .1rem dotted transparent;
    transform: translateY( .15rem );
    transition: text-indent .1s $transition-bezier;
  }

  &:focus strong, 
  &.page-bottom strong {
    position: relative;
    color: $color-slate;
    text-indent: 0;
  }

  &.page-bottom:active strong {
    border-color: transparent;
  }

  @media screen and (min-width: $breakpoint-md) {
    left: 3rem;

    &.page-bottom {
      bottom: 3rem;
    }

  }

  // Expand button on :hover on non-touch devices only
  @media (hover: hover) and (pointer: fine) {

    &:hover {
      width: auto;
      color: $color-slate;
      box-shadow: 0 0 1em rgba( 0, 0, 0, .15 );
      overflow: hidden;

      strong {
        position: relative;
        color: $color-slate;
        text-indent: 0;
      }

    }

    &.page-bottom:hover strong {
      border-color: $color-slate;
    }

  }

}
