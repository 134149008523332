/**
* CONTENTS
*
* ACCESSIBILITY
*
* LAYOUT
*/





/*------------------------------------*\
  #ACCESSIBILITY
\*------------------------------------*/


.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}





/*------------------------------------*\
  #LAYOUT
\*------------------------------------*/


@mixin clearfix() {

  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }

}